import { createApp } from 'vue';

import App from './App.vue';
import router from './router';  // Import Vue Router if you're using it
import { initKeycloak, logout } from './keycloak';  // Import the Keycloak initialization function
import api from './services/api'; 

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // needs additional webpack config!


// Initialize Keycloak and only mount the Vue app after authentication
initKeycloak(
  async () => {
    const app = createApp(App);

    router.isReady();

    // Determine if the current route is the home page ("/")
    const isHomePage = router.currentRoute.value.path === '/';

    app.config.globalProperties.$showHome = isHomePage;

    const response = await api.get("/user-info");
    const user = response.data;

    // Check if user is active; if not, log them out
    if (!user.active) {
      logout();
      return;
    }

    // Set the user object globally in the Vue instance
    localStorage.setItem('userRole', user.role);
    localStorage.setItem('user', JSON.stringify(user) );

    // If you're using Vue Router, register it here
    app.use(router);
    // Mount the app after Keycloak initialization is successful
    app.mount('#app');
  },
  () => {
    const app = createApp(App);

    router.isReady();

    // Determine if the current route is the home page ("/")
    const isHomePage = window.location.pathname === '/';

    app.config.globalProperties.$showHome = isHomePage;

    // If you're using Vue Router, register it here
    app.use(router);
    // Mount the app after Keycloak initialization is successful
    app.mount('#app');
  }
);
