<template>
  <div class="row justify-content-between mt-5 mb-2 page-heading">
    <h2 class="col-3">Nastavení práv</h2>
    <div class="col-4 gap-2 d-flex flex-end flex-row-reverse">
      <button @click="savePermissions" class="btn btn-success mt-3">
        Uložit
      </button>
    </div>
  </div>
  <div class="container mt-4 edit-area">
    <div
      class="d-flex flex-row mb-3 d-flex justify-content-between gap-2 flex-row flex-wrap"
    >
      <div class="col-md-2">
        <!-- Select Role -->
        <div class="form-group">
          <label for="roleSelect">Typ</label>
          <select
            v-model="selectedRole"
            @change="fetchRolePermissions"
            class="form-select mb-3"
          >
            <option v-for="role in roles" :key="role.id" :value="role.id">
              {{ role.rolename }}
            </option>
          </select>
        </div>

        <!-- Role Capabilities -->
        <div>
          <label for="canEditEntitiesAndCategories"
            >Správa entit a kategorií</label
          >
          <div class="form-switch fs-4">
            <input
              type="checkbox"
              class="form-check-input"
              id="canEditEntitiesAndCategories"
              v-model="selectedRolePermissions.can_edit_entities_and_categories"
            />
          </div>
        </div>
        <div>
          <label for="canEditSimpleReservations"
            >Správa jednoduchých rezervací</label
          >
          <div class="form-switch fs-4">
            <input
              type="checkbox"
              class="form-check-input"
              id="canEditSimpleReservations"
              v-model="selectedRolePermissions.can_edit_simple_reservations"
            />
          </div>
        </div>
        <div>
          <label for="canSeeAvailableEntities"
            >Vidí vyhledávání volných entit</label
          >
          <div class="form-switch fs-4">
            <input
              type="checkbox"
              class="form-check-input"
              id="canSeeAvailableEntities"
              v-model="selectedRolePermissions.can_see_available_entities"
            />
          </div>
        </div>
      </div>

      <!-- Entity-Specific Permissions -->
      <div
        class="col-md-2 d-flex flex-column flex-grow-1"
        v-for="permissionGroup in permissionGroups"
        :key="permissionGroup.label"
      >
          <div class="form-check checkbox-lg d-flex align-items-center gap-2" style="height: 70px;">
            <input
              type="checkbox"
              class="form-check-input"
              :id="'master-' + permissionGroup.group"
              @click="toggleAll(permissionGroup.group)"
              :indeterminate.prop="isIndeterminate(permissionGroup.group)"
              :checked="areAllChecked(permissionGroup.group)"
            />
            <label :for="'master-' + permissionGroup.group">{{
              permissionGroup.label
            }}</label>
          </div>
          <div class="checkboxes-wrap flex-grow-1">
            <div
              v-for="(entity, index) in entities"
              :key="entity.id"
              class="form-check checkbox-lg"
            >
              <input
                type="checkbox"
                class="form-check-input"
                :id="`${permissionGroup.group}-${entity.id}`"
                @click="
                  handleCheckboxClick(permissionGroup.group, index, $event)
                "
                v-model="entityPermissions[entity.id][permissionGroup.group]"
              />
              <label
                class="form-check-label"
                :for="`${permissionGroup.group}-${entity.id}`"
              >
                {{ entity.category.name + "/" + entity.name }}
              </label>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";

export default {
  data() {
    return {
      roles: [], // Available roles (Reader, Employee)
      selectedRole: null, // Selected role ID
      selectedRolePermissions: {
        can_edit_entities_and_categories: false,
        can_edit_simple_reservations: false,
        can_see_available_entities: false,
      }, // Permissions for selected role
      entities: [], // Available entities
      entityPermissions: {}, // Store permissions for each entity
      permissionGroups: [
        {
          label:
            "Entity, kde vidí jen obsazené bloky a žádné další informace k Rezervacím jiných Uživatelů",
          group: "can_only_see_reserved_blocks",
        },
        {
          label: "Entity, kde může dělat Rezervace",
          group: "can_make_reservation",
        },
        {
          label:
            "Entity, kde vidí informace v kalendáriu a dostane se do detailu Rezervace",
          group: "can_see_info_in_calendar",
        },
        {
          label: "Entity, kde může schvalovat Rezervace",
          group: "can_approve_reservation",
        },
      ],
      lastCheckedIndex: {},
    };
  },
  methods: {
    async fetchRoles() {
      const response = await api.get("/roles");
      this.roles = response.data;

      if (this.roles.length > 0) {
        this.selectedRole = this.roles[0].id;
        this.fetchRolePermissions(); // Fetch permissions for the preselected role
      }
    },

    async fetchEntities() {
      const response = await api.get("/entities");
      this.entities = response.data;

      // Initialize entityPermissions
      this.entities.forEach((entity) => {
        if (!this.entityPermissions[entity.id]) {
          this.entityPermissions[entity.id] = {
            can_only_see_reserved_blocks: false,
            can_make_reservation: false,
            can_see_info_in_calendar: false,
            can_approve_reservation: false,
          };
        }
      });
    },

    async fetchRolePermissions() {
      const response = await api.get(`/roles/${this.selectedRole}/permissions`);
      this.selectedRolePermissions = response.data;

      // Initialize entityPermissions for fetched permissions
      const permissions = this.selectedRolePermissions.entity_permissions || [];
      permissions.forEach((permission) => {
        this.entityPermissions[permission.entity_id] = {
          can_only_see_reserved_blocks: permission.can_only_see_reserved_blocks,
          can_make_reservation: permission.can_make_reservation,
          can_see_info_in_calendar: permission.can_see_info_in_calendar,
          can_approve_reservation: permission.can_approve_reservation,
        };
      });
    },

    async savePermissions() {
      await api.post(
        `/roles/${this.selectedRole}`,
        this.selectedRolePermissions
      );
      await api.post(`/roles/${this.selectedRole}/permissions`, {
        entity_permissions: this.entityPermissions,
      });
      alert("Práva byla úspěšně aktualizována");
    },

    // Check if all checkboxes in a group are selected
    areAllChecked(group) {
      return this.entities.every(
        (entity) => this.entityPermissions[entity.id][group]
      );
    },

    // Check if some checkboxes in a group are selected (for indeterminate state)
    isIndeterminate(group) {
      const checkedCount = this.entities.filter(
        (entity) => this.entityPermissions[entity.id][group]
      ).length;
      return checkedCount > 0 && checkedCount < this.entities.length;
    },

    // Toggle all checkboxes in a group
    toggleAll(group) {
      const newState = !this.areAllChecked(group);
      this.entities.forEach((entity) => {
        this.entityPermissions[entity.id][group] = newState;
      });
    },
    handleCheckboxClick(group, index, event) {
      if (event.shiftKey && this.lastCheckedIndex[group] !== null) {
        const start = Math.min(index, this.lastCheckedIndex[group]);
        const end = Math.max(index, this.lastCheckedIndex[group]);
        for (let i = start; i <= end; i++) {
          this.entityPermissions[this.entities[i].id][group] =
            event.target.checked;
        }
      }

      this.lastCheckedIndex[group] = index;
    },
  },
  created() {
    this.fetchRoles();
    this.fetchEntities();

    // Initialize lastCheckedIndex for each permission group
    this.permissionGroups.forEach((group) => {
      this.lastCheckedIndex[group.group] = null;
    });
  },
};
</script>
