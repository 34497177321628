<template>
  <div class="container">
    <div class="row mt-5 mb-2">
      <div class="d-flex mb-3 justify-content-between page-heading p-0">
        <div class="row">
          <div
            class="col-sm-12 d-flex gap-2 justify-content-between cat-ent-select-wrap"
          >
            <select
              v-model="selectedCategory"
              class="form-select"
              @change="fetchEntities"
            >
              <option
                v-for="category in categories"
                :key="category.id"
                :value="category.id"
                :selected="selectedCategory == category.id"
              >
                {{ category.name }}
              </option>
            </select>

            <select
              v-model="reservation.entity_id"
              class="form-select"
              @change="selectEntity"
            >
              <option
                v-for="entity in filteredEntities"
                :key="entity.id"
                :value="entity.id"
                :selected="selectedEntity == entity.id"
              >
                {{ entity.name }}
              </option>
            </select>
          </div>
          <div
            class="col-sm-12 mt-2"
            v-html="reservation.entity.description || ( entity && entity.description ) || ''"
          ></div>
        </div>
        <!-- Buttons -->
        <div class="col-sm-4 text-end">
          <button class="btn btn-secondary me-2" @click="closeForm">
            Zavřít
          </button>
          <button class="btn btn-info me-2" @click="duplicateReservation" v-if="reservation.id">
            Duplikovat
          </button>
          <button class="btn btn-danger me-2" @click="deleteReservation" v-if="reservation.id">
            Smazat
          </button>
          <button class="btn btn-success" @click="saveReservation" v-if="isAdmin || userCanMakeReservations">
            Uložit
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container edit-area small-button-text">
    <!-- Main Form: Columns -->
    <div class="row d-flex flex-row">
      <!-- Column 1: Reservation Details -->
      <div class="col-md-5">
        <div class="form-group">
          <label for="name">Název</label>
          <input
            type="text"
            v-model="reservation.name"
            class="form-control mt-2"
          />
        </div>

        <div class="form-group">
          <label for="description">Popis</label>
          <QuillEditor
            v-model:content="reservation.description"
            contentType="html"
            theme="snow"
          />
        </div>

        <div class="row">
          <div class="col-md-6">
            <label for="start_time">Začátek rezervace (DD.MM.YYYY HH:MM)</label>
            <input
              type="datetime-local"
              v-model="reservation.start_time"
              class="form-control"
            />
          </div>
          <div class="col-md-6">
            <label for="end_time">Konec rezervace (DD.MM.YYYY HH:MM)</label>
            <input
              type="datetime-local"
              v-model="reservation.end_time"
              class="form-control"
            />
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-3">
            <label for="capacity">Kapacita</label>
            <input
              type="number"
              v-model="reservation.capacity"
              class="form-control"
              min="0"
            />
          </div>
          <div
            class="col-md-3"
            v-if="
              isAdmin ||
              ( entity && entity.approval_required ) ||
              reservation.entity.approval_required ||
              ( entity && entity.user_can_approve && ( reservation.entity.approval_required || entity.approval_required ) )
            "
          >
            <label for="approved">Stav</label>
            <select
              v-model="reservation.approved"
              class="form-select"
              :disabled="
                !reservation.user_can_approve &&
                !reservation.entity.requires_approval &&
                (entity && !entity.user_can_approve )
              "
            >
              <option
                value="false"
                style="color: red"
                :selected="!reservation.approved"
              >
                Čeká na schválení
              </option>
              <option value="true" :selected="reservation.approved">
                Schváleno
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <label for="color">Barva</label>
            <div class="d-flex justify-content-between gap-1">
              <div
                v-for="color in colors"
                :key="color.value"
                class="col"
                :style="{
                  // padding: '0 1px 0',
                  width: 'px',
                }"
              >
                <input
                  type="radio"
                  :id="`color-${color.label}`"
                  v-model="reservation.color"
                  :value="color.value"
                  class="btn-check"
                />
                <label
                  :for="`color-${color.label}`"
                  class="btn"
                  :style="{
                    backgroundColor: color.value,
                    borderRadius: '5px',
                    width: '100%',
                    height: '30px',
                    padding: '0',
                    // display: 'inline-block',
                  }"
                ></label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 mt-3">
            <label for="registration_allowed">Registrace</label>
            <div class="form-switch fs-4">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="reservation.registration_allowed"
              />
            </div>
          </div>

          <div class="col-md-3 mt-2" v-if="reservation.registration_allowed">
            <label for="requires_approval">Schvalování registrací</label>

            <div class="form-switch fs-4">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="reservation.requires_approval"
              />
            </div>
          </div>

          <!-- Repeat and End Repeat Date -->
          <div class="col-md-3 form-group mt-3">
            <label for="repeat_type">Opakování</label>
            <select
              v-model="reservation.repeat_type"
              class="form-control"
              @change="handleRepeatTypeChange"
            >
              <option value="none">Nikdy</option>
              <option value="weekly">Týdně</option>
              <option value="monthly">Měsíčně</option>
            </select>
          </div>
          <div class="col-md-3" v-if="reservation.repeat_type !== 'none'">
            <label for="end_repeat_date" class="mt-2">Konec (DD.MM.YYYY)</label>
            <input
              type="date"
              v-model="reservation.end_repeat_date"
              class="form-control"
            />
          </div>
        </div>
        <div class="row" v-if="reservation.registration_allowed">
          <div class="col-md-6 form-group mt-3">
            <label for="registration_start_time"
              >Začátek registrace (DD.MM.YYYY)</label
            >
            <input
              type="datetime-local"
              v-model="reservation.registration_start_time"
              class="form-control"
            />
          </div>
          <div class="col-md-6 form-group mt-3">
            <label for="registration_end_time"
              >Konec registrace (DD.MM.YYYY)</label
            >
            <input
              type="datetime-local"
              v-model="reservation.registration_end_time"
              class="form-control"
            />
          </div>
        </div>
        <div class="row mt-2" v-if="reservation.registration_allowed">
          <div class="col">
            <label for="login_only_access">Jen pro uživatele s loginem</label>

            <div class="form-switch fs-4">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="reservation.login_only_access"
              />
            </div>
          </div>
        </div>

        <!-- Registration Link -->
        <div v-if="reservation.registration_allowed" class="mt-3">
          <label for="registration_link">Odkaz na rezervaci</label>
          <div class="d-flex">
            <input
              type="text"
              class="form-control flex-grow-1"
              :value="generateRegistrationLink()"
              readonly
              disabled
            />
            <a @click="copyToClipboard" class="btn btn-success ms-3">
              <svg
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.975 14.51a1.05 1.05 0 0 0 0-1.485 2.95 2.95 0 0 1 0-4.172l3.536-3.535a2.95 2.95 0 1 1 4.172 4.172l-1.093 1.092a1.05 1.05 0 0 0 1.485 1.485l1.093-1.092a5.05 5.05 0 0 0-7.142-7.142L9.49 7.368a5.05 5.05 0 0 0 0 7.142c.41.41 1.075.41 1.485 0zm2.05-5.02a1.05 1.05 0 0 0 0 1.485 2.95 2.95 0 0 1 0 4.172l-3.5 3.5a2.95 2.95 0 1 1-4.171-4.172l1.025-1.025a1.05 1.05 0 0 0-1.485-1.485L3.87 12.99a5.05 5.05 0 0 0 7.142 7.142l3.5-3.5a5.05 5.05 0 0 0 0-7.142 1.05 1.05 0 0 0-1.485 0z"
                  fill="#fff"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>

      <template v-if="reservation.id">
        <!-- Column 2: Waitlist Users -->
        <div
          class="col-md-2 d-flex flex-column flex-grow-1"
          v-if="reservation.requires_approval"
        >
          <div class="form-check checkbox-lg mb-2">
            <input
              type="checkbox"
              class="form-check-input"
              :id="'master-waitlistSelected'"
              @click="toggleAll('waitlistSelected')"
              :indeterminate.prop="isIndeterminate('waitlistSelected')"
              :checked="areAllChecked('waitlistSelected')"
            />
            <label :for="'master-waitlistSelected'">Žádosti o registraci</label>
          </div>
          <!-- registered_users -->
          <div class="checkboxes-wrap flex-grow-1">
            <div
              v-for="(user, index) in reservation.waitlist_users"
              :key="user.user_id"
              class="form-check checkbox-lg"
            >
              <TooltipInfo :text="user.notes">
                <input
                  type="checkbox"
                  v-model="waitlistSelected[index]"
                  class="form-check-input"
                  @click="
                    handleCheckboxClick('waitlistSelected', index, $event)
                  "
                />
                <label class="ms-2">
                  <router-link
                    :to="`/admin/users/${user.user.id}`"
                    v-if="isAdmin && user.user.is_active"
                  >
                    {{
                      user.user.name
                        ? user.user.name
                        : user.name || user.user.email
                    }}
                  </router-link>
                  <span v-else>
                    {{
                      user.user.name
                        ? user.user.name
                        : user.name || user.user.email
                    }}
                  </span>
                </label>
                <button
                  class="btn btn-delete btn-sm"
                  @click="removeUserFromWaitlist(user.user_id)"
                >
                  x
                </button>
              </TooltipInfo>
            </div>
          </div>
        </div>

        <!-- Move Buttons -->
        <div
          class="col-md-1 d-flex flex-column justify-content-center align-items-center user-moving-btns"
          :style="{
            width: '30px',
          }"
          v-if="reservation.requires_approval"
        >
          <button class="btn mb-2" @click="moveToApproved">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              fill="#000000"
              height="30px"
              width="30px"
              version="1.1"
              id="Layer_1"
              viewBox="0 0 330 330"
              xml:space="preserve"
            >
              <path
                id="XMLID_222_"
                d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001  c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213  C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606  C255,161.018,253.42,157.202,250.606,154.389z"
              />
            </svg>
          </button>
          <button class="btn" @click="moveToWaitlist">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              fill="#000000"
              height="30px"
              width="30px"
              version="1.1"
              id="Layer_1"
              viewBox="0 0 330.002 330.002"
              xml:space="preserve"
            >
              <path
                id="XMLID_227_"
                d="M233.25,306.001L127.5,165.005L233.25,24.001c4.971-6.628,3.627-16.03-3-21c-6.627-4.971-16.03-3.626-21,3  L96.75,156.005c-4,5.333-4,12.667,0,18l112.5,149.996c2.947,3.93,7.451,6.001,12.012,6.001c3.131,0,6.29-0.978,8.988-3.001  C236.878,322.03,238.221,312.628,233.25,306.001z"
              />
            </svg>
          </button>
        </div>

        <!-- Column 3: Approved Users -->
        <div
          class="col-md-2 d-flex flex-column flex-grow-1"
          v-if="reservation.registration_allowed"
        >
          <div
            class="form-check checkbox-lg"
            v-if="reservation.requires_approval"
          >
            <input
              type="checkbox"
              class="form-check-input"
              :id="'master-approvedUsers'"
              @click="toggleAll('approvedSelected')"
              :indeterminate.prop="isIndeterminate('approvedSelected')"
              :checked="areAllChecked('approvedSelected')"
            />
            <label :for="'master-approvedUsers'">Schválené registrace</label>
          </div>
          <!-- approvedUsers -->
          <div class="checkboxes-wrap flex-grow-1 mt-2">
            <div
              v-for="(user, index) in reservation.approved_users"
              :key="user.user_id"
              class="form-check checkbox-lg"
            >
              <TooltipInfo :text="user.notes">
                <input
                  type="checkbox"
                  v-model="approvedSelected[index]"
                  class="form-check-input"
                  @click="
                    handleCheckboxClick('approvedSelected', index, $event)
                  "
                  v-if="reservation.requires_approval"
                />
                <label class="ms-2">
                  <router-link
                    :to="`/admin/users/${user.user.id}`"
                    v-if="isAdmin && user.user.is_active"
                  >
                    {{
                      user.user.name
                        ? user.user.name
                        : user.name || user.user.email
                    }}
                  </router-link>
                  <span v-else>
                    {{
                      user.user.name
                        ? user.user.name
                        : user.name || user.user.email
                    }}
                  </span>
                </label>
                <button
                  class="btn btn-delete btn-sm"
                  @click="removeUserFromApproved(user.user_id)"
                >
                  x
                </button>
              </TooltipInfo>
            </div>
          </div>
          <!-- Approved User -->
          <div class="d-flex mt-2 justify-content-between">
            <div class="col-8">
              <VueSelect
                v-model="selectedUser"
                :options="userOptions"
                @search="searchUsers"
                placeholder="Zadejte jméno"
              >
                <template #no-options>
                <span>Žádné výsledky</span>
              </template>
            </VueSelect>
            </div>
            <button class="btn btn-success" @click="addUserToApproved">
              Přidat
            </button>
            <!-- Updated button label -->
          </div>
          <button class="btn mt-2 btn-success" @click="openMessageModal">
            Poslat zprávu registrovaným
          </button>

          <!-- Modal -->
          <div
            class="modal fade"
            id="messageModal"
            tabindex="-1"
            aria-labelledby="messageModalLabel"
            aria-hidden="true"
            ref="messageModal"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="messageModalLabel">
                    Poslat zprávu registrovaným uživatelům
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    @click="closeMessageModal"
                    aria-label="Zavřít"
                  ></button>
                </div>
                <div class="modal-body">
                  <textarea
                    v-model="messageText"
                    class="form-control"
                    placeholder="Zadejte zprávu..."
                    rows="5"
                  ></textarea>
                </div>
                <div class="modal-footer">
                  <button
                    @click="sendMessageToApproved"
                    class="btn btn-success"
                  >
                    Odeslat
                  </button>
                  <button @click="closeMessageModal" class="btn btn-secondary">
                    Zavřít
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Column 4: Admins -->
        <div class="col-md-2 d-flex flex-column flex-grow-1">
          <label for="">Správci</label>
          <!-- admins  -->
          <div class="checkboxes-wrap flex-grow-1 mt-2">
            <div v-for="admin in reservation.administrators" :key="admin.id">
              <label>
                <router-link :to="`/admin/users/${admin.id}`" v-if="isAdmin">{{
                  admin.name
                }}</router-link>
                <span v-else>
                  {{ admin.name }}
                </span>
              </label>
              <button
                class="btn btn-delete btn-sm"
                v-if="admin.id != reservation.user_id"
                @click="removeAdmin(admin.id)"
              >
                x
              </button>
            </div>
          </div>
          <!-- User/ Admin Select and Add Button -->
          <div class="d-flex mt-2 justify-content-between">
            <div class="col-8">
              <VueSelect
                v-model="selectedAdmin"
                :options="userOptions"
                @search="searchUsers"
                placeholder="Zadejte jméno"
              >
              <template #no-options>
                <span>Žádné výsledky</span>
              </template>
            </VueSelect>
            </div>
            <button class="btn btn-success" @click="addAdmin">Přidat</button>
          </div>
        </div>
      </template>
    </div>
    <!-- Overlapping Reservations Modal -->
    <div
      v-if="overlapConflicts.length > 0"
      class="modal fade"
      id="overlapModal"
      tabindex="-1"
      aria-labelledby="overlapModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="overlapModalLabel">
              Bylo zjištěno překrývání
            </h5>
            <button
              type="button"
              class="btn-close"
              @click="clearOverlap"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>{{ overlapConflictsMessage }}</p>
            <ul>
              <li v-for="conflict in overlapConflicts" :key="conflict.id">
                {{ conflict.name || conflict.conflict_reason }} ({{ conflict.start_time }} -
                {{ conflict.end_time }})
              </li>
            </ul>
            <p>Chtěli byste:</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" @click="saveAllowedReservations">
              Uložit pouze tam, kde je to možné
            </button>
            <button class="btn btn-secondary" @click="clearOverlap">
              Zrušit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="errorModal"
    tabindex="-1"
    aria-labelledby="messageModalLabel"
    aria-hidden="true"
    ref="errorModal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <!-- Display the dynamic message here -->
          <p>{{ modalInfoMessage }}</p>
          <p>{{ modalInfoAdditionalMessage }}</p>
        </div>
        <div class="modal-footer">
          <button @click="closeModal" class="btn btn-secondary">Zavřít</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import api from "../services/api";
import TooltipInfo from "./TooltipInfo.vue";
import { Modal } from "bootstrap";

import VueSelect from "vue3-select-component";

export default {
  components: {
    TooltipInfo,
    QuillEditor,
    VueSelect,
  },
  data() {
    return {
      isAdmin: false,
      userCanMakeReservations: true,
      categories: [],
      messageText: "",
      filteredEntities: [],
      allUsers: [],
      registered_users: [],
      approvedUsers: [],
      admins: [],
      selectedCategory: this.$route.query.category || null,
      selectedEntity: null,
      user: {},
      isDuplicated: false,
      modal: null,
      entity: {
        approval_required: false,
        description: "",
        user_can_approve: false,
      },
      modalInfoMessage: "",
      modalInfoAdditionalMessage: "",
      reservation: {
        id: undefined,
        name: "",
        description: "",
        start_time: this.$route.query.start || "",
        end_time: this.$route.query.end || "",
        capacity: "",
        requires_approval: false,
        registration_allowed: false,
        registration_start_time: "",
        registration_end_time: "",
        repeat_type: "none",
        end_repeat_date: "",
        approved: false,
        color: "#da6c6b",
        entity_id: this.$route.query.entity && this.$route.query.entity != 0 ? this.$route.query.entity : null,
        login_only_access: true,
        approved_users: [],
        waitlist_users: [],
        user_can_approve: false,
        entity: {
          description: "",
        },
        administrators: [],
        registration_link: "",
      },
      colors: [
        { value: "#da6c6b", label: "Red" },
        { value: "#f8a33b", label: "Orange" },
        { value: "#a9da6b", label: "Light Green" },
        { value: "#58d16d", label: "Green" },
        { value: "#61c8cb", label: "Blue" },
        { value: "#86a2cd", label: "Darkblue" },
        { value: "#7c62da", label: "Purple" },
        { value: "#b357db", label: "Violet" },
        { value: "#c67f97", label: "#c67f97" },
        { value: "#c9bb5d", label: "#c9bb5d" },
      ],
      selectedUser: null,
      selectedAdmin: null,
      waitlistSelected: [],
      approvedSelected: [],
      adminSelected: [],
      overlapConflicts: [],
      overlapConflictsMessage: "",
      errorMessage: "",
      lastCheckedIndex: {
        waitlistSelected: null,
        approvedSelected: null,
      },
      userOptions: [],
      modalInstance: null,
    };
  },
  methods: {
    async fetchEntities() {
      try {
        const response = await api.get(
          `/entities?category_id=${this.selectedCategory}`
        );
        this.filteredEntities = response.data;
        if( this.reservation.entity_id ) {
          if( this.reservation.id ) {
            this.filteredEntities.push(this.reservation.entity)
          }
          this.selectEntity();
        }
      } catch (error) {
        console.error("Error fetching entities:", error);
      }
    },

    selectEntity() {
      this.entity = this.filteredEntities.filter(
        (entity) => entity.id === parseInt(this.reservation.entity_id)
      )[0];
      this.userCanMakeReservations = this.entity.can_make_reservation;
      console.log(this.entity);
    },
    // Logic to close the form
    closeForm() {
      this.$router.push("/");
    },

    // Logic to duplicate reservation
    // async duplicateReservation() {
    //   try {
    //     const duplicate = { ...this.reservation };
    //     delete duplicate.id;
    //     const response = await api.post("/reservations", duplicate);
    //     alert("Rezervace byla úspěšně duplikována");
    //     this.$router.push(`/reservations/${response.data.id}`);
    //   } catch (error) {
    //     console.error("Error duplicating reservation:", error);
    //   }
    // },

    openModal() {
      this.modalInstance = new Modal(this.$refs.errorModal, {});
      this.modalInstance.show();
    },

    // Close the message modal
    closeModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    },

    openMessageModal() {
      this.modalInstance = new Modal(this.$refs.messageModal, {});
      this.modalInstance.show();
    },

    // Close the message modal
    closeMessageModal() {
      if (this.modalInstance) {
        this.modalInstance.hide();
      }
    },

    async duplicateReservation() {
      try {
        if( this.entity.time_overlap ) {
          const response = await api.post(
            `/reservations/${this.reservation.id}/duplicate`
          );
          this.reservation = response.data.reservation;
          this.isDuplicated = true; // Set the flag to indicate it's duplicated
          const newReservationId = response.data.reservation.id; // Get the new reservation ID

          // Redirect to the new reservation edit page
          this.$router.push(`/reservations/${newReservationId}`);
        } else {
          this.reservation.id = undefined;
          this.isDuplicated = true; // Set the flag to indicate it's duplicated
          // Redirect to the new reservation edit page
          this.$router.push(`/reservation/`);
        }

        alert("Rezervace byla úspěšně duplikována");
      } catch (error) {
        console.error("Error duplicating reservation:", error);
        alert("Došlo k chybě při duplikaci rezervace");
      }
    },
    async searchUsers(searchTerm) {
      if (!searchTerm) return;

      try {
        const response = await api.get("/users-search", {
          params: { search: searchTerm },
        });
        this.userOptions = response.data.map((user) => ({
          value: user.id,
          label: `${user.name}`,
        }));
        console.log(this.userOptions);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },

    // Logic to delete reservation
    async deleteReservation() {
      if (
        confirm(
          "Tato akce je nevratná! Po kliknutí na tlačítko OK se Rezervace smaže."
        )
      ) {
        try {
          await api.delete(`/reservations/${this.reservation.id}`);
          alert("Rezervace byla smazána");
          this.$router.push("/reservations");
        } catch (error) {
          console.error("Nastala chyba při mazání rezervace:", error);
        }
      }
    },

    // Logic to save or update reservation
    async saveReservation() {
      this.modalInfoAdditionalMessage ='';
      try {
        if (this.reservation.id) {
          // Update existing reservation
          const response = await api.put(
            `/reservations/${this.reservation.id}`,
            this.reservation
          );
          if (response.data.message) {
            this.modalInfoMessage = response.data.message;

            this.openModal();
          }
        } else {
          // Create new reservation
          const response = await api.post("/reservations", this.reservation);
          this.reservation.id = response.data.reservation.id; // Set the newly created reservation's ID
          this.reservation = response.data.reservation;
          if (response.data.message) {
            this.openModal();

            this.modalInfoMessage = response.data.message;
          }
          this.$router.push(`/reservations/${this.reservation.id}`);
        }
      } catch (error) {
        if (error.response && error.response.data.overlapping_reservations) {
          if( error.response.data.original_reservation_id ) {
            this.reservation.id = error.response.data.original_reservation_id;
          }

          this.overlapConflicts = error.response.data.overlapping_reservations;
          this.overlapConflictsMessage = error.response.data.message || 'Rezervace se překrývá s těmito jinými rezervacemi:';
          // Access Bootstrap Modal
          this.$nextTick(() => {
            const modalElement = document.getElementById("overlapModal");
            this.modal = new Modal(modalElement); // Initialize the Bootstrap Modal instance
            this.modal.show(); // Show the modal
          });

          // const modal = new window.bootstrap.Modal(
          //   document.getElementById("overlapModal")
          // );
          // modal.show();
        } else {
          this.modalInfoMessage = error.response.data.message;
          if (error.response.data.available_hours) {
            this.modalInfoAdditionalMessage =
              error.response.data.available_hours;
          }
          this.openModal();

          this.errorMessage =
            error.response.data.message || "Chyba uložení rezervace";
        }
      }
    },

    async moveToApproved() {
      const selectedUsers = this.reservation.waitlist_users.filter(
        (_, index) => this.waitlistSelected[index]
      );

      for (let user of selectedUsers) {
        try {
          // API call to move the user to the approved list
          await api.post(`/reservations/${this.reservation.id}/approved`, {
            user_id: user.user_id,
          });

          // Update local state after successful approval
          this.reservation.approved_users.push(user);
          this.reservation.waitlist_users =
            this.reservation.waitlist_users.filter(
              (waitlistUser) => waitlistUser.user_id !== user.user_id
            );
        } catch (error) {
          console.error("Error moving user to approved:", error);
        }
      }

      // Clear selection after operation
      this.waitlistSelected = [];
    },

    // Move selected users from approved to waitlist
    async moveToWaitlist() {
      const selectedUsers = this.reservation.approved_users.filter(
        (_, index) => this.approvedSelected[index]
      );

      for (let user of selectedUsers) {
        try {
          // API call to move the user back to the waitlist
          await api.post(`/reservations/${this.reservation.id}/waitlist`, {
            user_id: user.user_id,
          });

          // Update local state after successful move back to waitlist
          this.reservation.waitlist_users.push(user);
          this.reservation.approved_users =
            this.reservation.approved_users.filter(
              (approvedUser) => approvedUser.user_id !== user.user_id
            );
        } catch (error) {
          console.error("Error moving user to waitlist:", error);
        }
      }

      // Clear selection after operation
      this.approvedSelected = [];
    },

    // Remove user from waitlist
    async removeUserFromWaitlist(userId) {
      try {
        await api.delete(
          `/reservations/${this.reservation.id}/waitlist/${userId}`
        );
        this.reservation.waitlist_users =
          this.reservation.waitlist_users.filter(
            (user) => user.user_id !== userId
          );
      } catch (error) {
        console.error("Error removing user from waitlist:", error);
      }
    },

    // Remove user from approved list
    async removeUserFromApproved(userId) {
      try {
        await api.delete(
          `/reservations/${this.reservation.id}/approved/${userId}`
        );
        this.reservation.approved_users =
          this.reservation.approved_users.filter(
            (user) => user.user_id !== userId
          );
      } catch (error) {
        console.error("Error removing user from approved list:", error);
      }
    },

    copyToClipboard() {
      navigator.clipboard
        .writeText(this.reservation.registration_link)
        .then(() => {
          alert("Odkaz na rezervaci zkopírován do schránky");
        })
        .catch((err) => {
          console.error("Failed to copy link: ", err);
        });
    },

    // Add a user to the approved list
    async addUserToApproved() {
      if (this.selectedUser) {
        try {
          const response = await api.post(
            `/reservations/${this.reservation.id}/approved`,
            {
              user_id: this.selectedUser,
            }
          );

          if (response.data.message) {
            this.openModal();

            this.modalInfoMessage = response.data.message;
          }

          this.reservation.approved_users.push(response.data.data);
          this.selectedUser = null;
        } catch (error) {
          if (error.response.data.message) {
            this.openModal();

            this.modalInfoMessage = error.response.data.message;
          }
          console.error("Error adding user to approved list:", error);
        }
      }
    },

    // Logic to add admin to the reservation
    async addAdmin() {
      try {
        const response = await api.post(
          `/reservations/${this.reservation.id}/admins`,
          { user_id: this.selectedAdmin }
        );
        this.reservation.administrators.push(response.data);
        alert("Admin úspěšně přidán");
        this.selectedAdmin = null;
      } catch (error) {
        console.error("Error adding admin:", error);
      }
    },

    // Logic to remove admin from the reservation
    async removeAdmin(adminId) {
      if (confirm("Chcete opravdu odstranit tohoto admina?")) {
        try {
          this.reservation.administrators =
            this.reservation.administrators.filter(
              (admin) => admin.id !== adminId
            );
          await api.delete(
            `/reservations/${this.reservation.id}/admins/${adminId}`
          );
        } catch (error) {
          console.error("Error removing admin:", error);
        }
      }
    },

    handleRepeatTypeChange() {
      if (
        this.reservation.repeat_type === "weekly" ||
        this.reservation.repeat_type === "monthly"
      ) {
        this.setEndRepeatDate();
      } else {
        this.reservation.end_repeat_date = ""; // Clear if no repeat
      }
    },
    setEndRepeatDate() {
      if (this.reservation.end_time) {
        const endDate = new Date(this.reservation.start_time);
        endDate.setFullYear(endDate.getFullYear() + 1); // Add 12 months
        this.reservation.end_repeat_date = endDate.toISOString().split("T")[0]; // Format to yyyy-mm-dd
      }
    },

    // Logic to send a message to approved users
    async sendMessageToApproved() {
      if (!this.messageText) {
        alert("Napište prosím zprávu před odesláním.");
        return;
      }
      try {
        await api.post(`/reservations/${this.reservation.id}/message`, {
          message: this.messageText, // Message from the modal textarea
        });
        alert("Zpráva byla úspěšně odeslána registrovaným uživatelům.");
        this.closeMessageModal();
      } catch (error) {
        console.error("Error sending message:", error);
        alert("Nastala chyba při odesílání zprávy.");
      }
    },
    generateRegistrationLink() {
      if (!this.reservation.registration_code) {
        // You might want to call an API to generate and save a registration code if it doesn't exist
        console.error("Registration code is missing");
        return "";
      }
      this.reservation.registration_link = `${process.env.VUE_APP_URL}/reservations/${this.reservation.registration_code}/register`;
      return this.reservation.registration_link;
    },
    async saveAllowedReservations() {
      try {
        // Send the reservation with non-overlapping data to the server
        const response = await api.post(
          `/reservations/${this.reservation.id}/save-allowed`,
          this.reservation
        );
        alert("Rezervace byly uloženy tam, kde to bylo možné");

        // Hide the modal and clear conflicts
        this.clearOverlap();

        this.reservation.id = response.data.reservation.id; // Set the newly created reservation's ID
        this.reservation = response.data.reservation;
        this.$router.push(`/reservations/${this.reservation.id}`);
      } catch (error) {
        if (error.response.data.message) {
          this.clearOverlap();
          this.openModal();
          this.modalInfoMessage = error.response.data.message;
        }
      }
    },

    clearOverlap() {
      // Clear the overlapping conflicts and close the modal
      this.overlapConflicts = [];
      if (this.modal) {
        this.modal.hide();
      }
    },
    async fetchReservation() {
      if (this.$route.params.id) {
        try {
          const response = await api.get(
            `/reservations/${this.$route.params.id}`
          );
          this.reservation = response.data;
          this.selectedCategory = response.data.entity.category_id;
          this.selectedEntity = response.data.entity_id;
          this.reservation.entity_id = response.data.entity.id;

          if (
            this.reservation.registration_code !== "" &&
            this.reservation.registration_allowed &&
            !this.reservation.user_can_approve &&
            !this.reservation.user_can_see_page
          ) {
            this.$router.push(
              `/reservations/${this.reservation.registration_code}/register`
            );
          }
        } catch (error) {
          console.error("Error fetching reservation:", error);
        }
      }
    },
    // Toggle all checkboxes in a group
    toggleAll(group) {
      const newState = !this.areAllChecked(group);
      if (group === "waitlistSelected") {
        this.waitlistSelected = this.reservation.waitlist_users.map(
          () => newState
        );
      } else if (group === "approvedSelected") {
        this.approvedSelected = this.reservation.approved_users.map(
          () => newState
        );
      }
    },

    // Handle Shift-click to select a range of checkboxes
    handleCheckboxClick(group, index, event) {
      if (event.shiftKey && this.lastCheckedIndex[group] !== null) {
        const start = Math.min(index, this.lastCheckedIndex[group]);
        const end = Math.max(index, this.lastCheckedIndex[group]);
        for (let i = start; i <= end; i++) {
          if (group === "waitlistSelected") {
            this.waitlistSelected[i] = event.target.checked; // Directly set the value
          } else if (group === "approvedSelected") {
            this.approvedSelected[i] = event.target.checked; // Directly set the value
          }
        }
      }
      this.lastCheckedIndex[group] = index; // Update the last checked index
    },

    // Check if all checkboxes in a group are selected
    areAllChecked(group) {
      if (group === "waitlistSelected") {
        return (
          this.waitlistSelected.length > 0 &&
          this.waitlistSelected.every(Boolean)
        );
      } else if (group === "approvedSelected") {
        return (
          this.approvedSelected.length > 0 &&
          this.approvedSelected.every(Boolean)
        );
      }
    },

    // Check if some checkboxes in a group are selected (for indeterminate state)
    isIndeterminate(group) {
      if (group === "waitlistSelected") {
        const selectedCount = this.waitlistSelected.filter(Boolean).length;
        return (
          selectedCount > 0 &&
          selectedCount < this.reservation.waitlist_users.length
        );
      } else if (group === "approvedSelected") {
        const selectedCount = this.approvedSelected.filter(Boolean).length;
        return (
          selectedCount > 0 &&
          selectedCount < this.reservation.approved_users.length
        );
      }
    },
    checkUserRole() {
      const userRole = localStorage.getItem("userRole");
      if (userRole === "admin") {
        this.isAdmin = true; // Mark the user as admin if they have the admin role
      }
    },
  },
  async mounted() {
    this.checkUserRole();
    // this.fetchEntities();

    try {
      const categoriesResponse = await api.get("/categories");
      this.categories = categoriesResponse.data;

      const usersResponse = await api.get("/users");
      this.allUsers = usersResponse.data;
    } catch (error) {
      console.error("Error fetching data on mount:", error);
    }

    // Fetch entities if category is selected

    // this.fetchReservation();

    if (this.selectedCategory) {
      this.fetchEntities();
    }

    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    tooltipTriggerList.forEach(
      (tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl)
    );
  },
  created() {
    this.checkUserRole();
    // this.fetchEntities();

    // Fetch entities if category is selected

    this.fetchReservation();

    if (this.selectedCategory) {
      this.fetchEntities();
    }

    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    tooltipTriggerList.forEach(
      (tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl)
    );
  },
  watch: {
    // If end_time changes, update end_repeat_date if repeat is enabled
    "reservation.end_time"() {
      if (
        this.reservation.repeat_type === "weekly" ||
        this.reservation.repeat_type === "monthly"
      ) {
        this.setEndRepeatDate();
      }
    },
  },
};
</script>
<style>
@import "~quill/dist/quill.snow.css";
:root {
  --vs-font-size: 13px;
  --vs-icon-color: #343a40;
  --vs-menu-offset-top: 0px;
}
.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  background-color: white;
}
.ql-editor {
  min-height: 100px;
}
.vue-select .indicators-container {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  padding: 0;
}
.vue-select .indicators-container svg {
  fill: #343a40;
}
.ql-snow .ql-tooltip[data-mode=link]::before{
  content: "Zadejte odkaz:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after{
  content: 'Uložit';
}
.ql-snow .ql-tooltip::before {
  content: "Navštivte URL:";
}
.ql-snow .ql-tooltip a.ql-action::after {
    content: 'Upravit';
}
.ql-snow .ql-tooltip a.ql-remove::before {
  content: 'Smazat';
}
</style>
<style scoped>

.container {
  max-width: 1200px;
  margin: 0 auto;
}
.cat-ent-select-wrap select {
  min-width: 250px;
}
@media (max-width: 767px) {
  .page-heading {
    flex-wrap: wrap;
  }
  .page-heading > div {
    flex-basis: 100%;
  }
  .page-heading > div.col-md-4 {
    justify-content: space-between !important;
  }
  .user-moving-btns {
    flex-direction: row !important;
    text-align: center;
    width: 100% !important;
  }
  .user-moving-btns button {
    transform: rotate(90deg);
  }
  .cat-ent-select-wrap select {
    min-width: 100px;
  }
}
</style>
