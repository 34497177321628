<template>
  <div class="row justify-content-between mt-5 mb-2 page-heading">
    <h2 class="col-sm-3">Rezervace</h2>
    <div class="col-sm-4 gap-2 d-flex flex-end flex-row-reverse">
      <button @click="createSimpleReservation" class="btn btn-success me-2">
        Nová jednoduchá rezervace
      </button>
      <button @click="createReservation" class="btn btn-success">
        Nová rezervace
      </button>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table align-middle">
      <thead class="table-light align-top">
        <tr>
          <th>
            Název<br />
            <input type="text" class="mt-2" v-model="filterName"/>
          </th>
          <th>Začátek rezervace</th>
          <th>Konec rezervace</th>
          <th>
            Kde
            <br />
            <select v-model="filterEntity" class="form-select">
              <option value="">Všechny kategorie</option>
              <option v-for="entity in entities" :key="entity.id" :value="entity.id">
                {{ entity.category.name + '/' + entity.name }}
              </option>
            </select>
          </th>
          <th>Stav</th>
          <th  class="col-md-2 text-end">
            <button class="btn btn-secondary mb-2" @click="filterData">Filtrovat</button><br />
            <button class="btn btn-secondary" @click="filterClear">Vyčistit</button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="reservation in reservations" :key="reservation.id">
          <td>{{ reservation.name }}</td>
          <td>{{ formatDateTime(reservation.start_time) }}</td>
          <td>{{ formatDateTime(reservation.end_time) }}</td>
          <td>{{ reservation.entity.category.name + '/' + reservation.entity.name }}</td>
          <td  :style="{ color: reservation.approved ? 'green' : 'red' }" v-if="reservation.type === 'regular'">
            {{ reservation.approved ? "Schváleno" : "Čeká na schválení" }}
          </td>
          <td  :style="{ color: 'green' }" v-if="reservation.type === 'simple'">
            {{ "Schváleno" }}
          </td>
          <td class="text-end">
            <router-link 
            v-if="reservation.type === 'regular'"
              :to="`/reservations/${reservation.id}`"
              class="btn btn-success"
              >Detail</router-link
            >
            <router-link 
            v-if="reservation.type === 'simple'"
              :to="`/simple-reservation/${reservation.id}`"
              class="btn btn-success"
              >Detail</router-link
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="pagination-controls">
      <button
        class="btn btn-primary"
        @click="fetchReservations(currentPage - 1)"
        :disabled="currentPage === 1"
      >
        Předchozí
      </button>
      <span>Stránka {{ currentPage }} z {{ lastPage }}</span>
      <button
        class="btn btn-primary"
        @click="fetchReservations(currentPage + 1)"
        :disabled="currentPage === lastPage"
      >
        Další
      </button>
    </div>
</template>

<script>
import api from "../../services/api";

export default {
  data() {
    return {
      filterName: '',
      filterEntity: '',
      reservations: [],
      entities: [],
      currentPage: 1,
      lastPage: 1, // Total number of pages
    };
  },
  mounted() {
    this.fetchReservations(this.currentPage);
    this.fetchEntities();
  },
  methods: {
    formatDateTime(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);

      // Extract date parts
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      // Construct the desired format
      return `${day}.${month}.${year} ${hours}:${minutes}`;
    },
    async fetchReservations(page = 1) {
      try {
        const response = await api.get(`/reservations-list`, {
          params: {
            name: this.filterName,
            entity_id: this.filterEntity,
            page: page,
          }
        });
        this.reservations = response.data.data;
        this.currentPage = response.data.current_page;
        this.lastPage = response.data.last_page;
      } catch (error) {
        console.error("Error fetching reservations:", error);
      }
    },

    async fetchEntities() {
      try {
        const response = await api.get(
          `/entities`
        );
        this.entities = response.data;
      } catch (error) {
        console.error("Error fetching entities:", error);
      }
    },

    filterData() {
      this.fetchReservations();
    },
    filterClear() {
      this.filterName = '';
      this.filterEntity = '';
      this.fetchReservations();
    },

    createSimpleReservation() {
      // Redirect to the Simple Reservation form with start and end time pre-filled
      this.$router.push({
        path: "/simple-reservation",
      });
    },
    createReservation() {
      // Redirect to the Reservation form with start and end time pre-filled
      this.$router.push({
        path: "/reservation",
      });
    },
  },
};
</script>
