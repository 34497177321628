<template>
  <div class="row justify-content-between mt-5 mb-2 page-heading">
    <h2 class="col-6 col-sm-3">Editace uživatele</h2>
    <div class="col-6 col-sm-4 gap-2 d-flex flex-end flex-row-reverse">
      <button class="btn btn-success" @click="savePermissions">Uložit</button>
    </div>
  </div>

  <div class="container mt-4 edit-area">
    <!-- Basic User Info (Readonly) -->
    <div class="row mb-3 d-flex justify-content-between gap-2 flex-row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="lastName">Příjmení</label>
          <input
            type="text"
            class="form-control"
            id="lastName"
            v-model="user.last_name"
            readonly
            disabled
          />
        </div>
        <div class="form-group">
          <label for="firstName">Jméno</label>
          <input
            type="text"
            class="form-control"
            id="firstName"
            v-model="user.first_name"
            readonly
            disabled
          />
        </div>
        <div class="form-group">
          <label for="email">E-mail</label>
          <input
            type="email"
            class="form-control"
            id="email"
            v-model="user.email"
            readonly
            disabled
          />
        </div>
        <div class="form-group">
          <label for="role">Typ</label>
          <input
            type="text"
            class="form-control"
            id="role"
            v-model="user.rolename"
            readonly
            disabled
          />
        </div>

        <div v-if="user.rolename !== 'admin'">
          <label for="disableTypeUser">Odpojit od typu uživatele</label>
          <div class="form-switch fs-4">
            <input
              type="checkbox"
              class="form-check-input"
              id="disableTypeUser"
              v-model="user.disable_type_user"
              @change="refreshPermissions"
            />
          </div>
        </div>

        <div>
          <label for="isActive">Aktivní</label>
          <div class="form-switch fs-4">
            <input
              type="checkbox"
              class="form-check-input"
              id="isActive"
              v-model="user.is_active"
            />
          </div>
        </div>

        <div v-if="user.rolename !== 'admin'">
          <label for="canEditEntitiesAndCategories"
            >Správa entit a kategorií</label
          >
          <div class="form-switch fs-4">
            <input
              type="checkbox"
              class="form-check-input"
              id="canEditEntitiesAndCategories"
              v-model="user.can_edit_entities_and_categories"
              :disabled="!user.disable_type_user"
            />
          </div>
        </div>
        <div v-if="user.rolename !== 'admin'">
          <label for="canEditSimpleReservations"
            >Správa jednoduchých rezervací</label
          >
          <div class="form-switch fs-4">
            <input
              type="checkbox"
              class="form-check-input"
              id="canEditSimpleReservations"
              v-model="user.can_edit_simple_reservations"
              :disabled="!user.disable_type_user"
            />
          </div>
        </div>
        <div v-if="user.rolename !== 'admin'">
          <label for="canSeeAvailableEntities"
            >Vidí vyhledávání volných entit</label
          >
          <div class="form-switch fs-4">
            <input
              type="checkbox"
              class="form-check-input"
              id="canSeeAvailableEntities"
              v-model="user.can_see_available_entities"
              :disabled="!user.disable_type_user"
            />
          </div>
        </div>
      </div>

      <!-- Conditionally Show Entity Permissions when disable_type_user is true -->
      <div
        class="col-md-2 d-flex flex-column flex-grow-1"
        v-if="user.rolename !== 'admin'"
      >
        <!-- Can Only See Reserved Blocks -->
        <div class="form-check checkbox-lg d-flex align-items-center gap-2">
          <input
            type="checkbox"
            class="form-check-input"
            id="masterCanOnlySeeReservedBlocks"
            @click="toggleAll('can_only_see_reserved_blocks')"
            v-if="user.disable_type_user"
            :indeterminate.prop="
              isIndeterminate('can_only_see_reserved_blocks')
            "
            :checked="areAllChecked('can_only_see_reserved_blocks')"
          />
          <label for="masterCanOnlySeeReservedBlocks"
            >Entity, kde vidí jen obsazené bloky a žádné další informace k
            Rezervacím jiných Uživatelů</label
          >
        </div>
        <div class="checkboxes-wrap flex-grow-1">
          <div
            v-for="(entity, index) in entities"
            :key="entity.id"
            class="form-check checkbox-lg"
          >
            <input
              type="checkbox"
              class="form-check-input"
              :id="'canOnlySeeReservedBlocks-' + entity.id"
              v-bind:disabled="!user.disable_type_user"
              @click="
                handleCheckboxClick(
                  'can_only_see_reserved_blocks',
                  index,
                  $event
                )
              "
              v-model="
                entityPermissions[entity.id].can_only_see_reserved_blocks
              "
            />
            <label
              class="form-check-label"
              :for="'canOnlySeeReservedBlocks-' + entity.id"
              >{{ entity.category.name + "/" + entity.name }}</label
            >
          </div>
        </div>
      </div>
      <div class="col-md-2 d-flex flex-column flex-grow-1" v-if="user.rolename !== 'admin'">
        <!-- Can Make Reservations -->
        <div class="form-check checkbox-lg d-flex align-items-center gap-2">
          <input
            type="checkbox"
            class="form-check-input"
            id="masterCanMakeReservation"
            @click="toggleAll('can_make_reservation')"
            v-if="user.disable_type_user"
            :indeterminate.prop="isIndeterminate('can_make_reservation')"
            :checked="areAllChecked('can_make_reservation')"
          />
          <label for="canMakeReservation"
            >Entity, kde může dělat Rezervace</label
          >
        </div>
        <div class="checkboxes-wrap flex-grow-1">
          <div
            v-for="(entity, index) in entities"
            :key="entity.id"
            class="form-check checkbox-lg"
          >
            <input
              type="checkbox"
              class="form-check-input"
              :id="'canMakeReservation-' + entity.id"
              v-bind:disabled="!user.disable_type_user"
              @click="
                handleCheckboxClick('can_make_reservation', index, $event)
              "
              v-model="entityPermissions[entity.id].can_make_reservation"
            />
            <label
              class="form-check-label"
              :for="'canMakeReservation-' + entity.id"
              >{{ entity.category.name + "/" + entity.name }}</label
            >
          </div>
        </div>
      </div>
      <div class="col-md-2 d-flex flex-column flex-grow-1" v-if="user.rolename !== 'admin'">
        <!-- Can See Info in Calendar -->
        <div class="form-check checkbox-lg d-flex align-items-center gap-2">
          <input
            type="checkbox"
            class="form-check-input"
            id="masterCanSeeInfoInCalendar"
            @click="toggleAll('can_see_info_in_calendar')"
            :indeterminate.prop="isIndeterminate('can_see_info_in_calendar')"
            :checked="areAllChecked('can_see_info_in_calendar')"
            v-if="user.disable_type_user"
          />
          <label for="canSeeInfoInCalendar"
            >Entity, kde vidí informace v kalendáriu a dostane se do detailu
            Rezervace</label
          >
        </div>
        <div class="checkboxes-wrap flex-grow-1">
          <div
            v-for="(entity, index) in entities"
            :key="entity.id"
            class="form-check checkbox-lg"
          >
            <input
              type="checkbox"
              class="form-check-input"
              :id="'canSeeInfoInCalendar-' + entity.id"
              v-bind:disabled="!user.disable_type_user"
              @click="
                handleCheckboxClick('can_see_info_in_calendar', index, $event)
              "
              v-model="entityPermissions[entity.id].can_see_info_in_calendar"
            />
            <label
              class="form-check-label"
              :for="'canSeeInfoInCalendar-' + entity.id"
              >{{ entity.category.name + "/" + entity.name }}</label
            >
          </div>
        </div>
      </div>
      <div class="col-md-2 d-flex flex-column flex-grow-1" v-if="user.rolename !== 'admin'">
        <!-- Can Approve Reservations -->
        <div class="form-check checkbox-lg d-flex align-items-center gap-2">
          <input
            type="checkbox"
            class="form-check-input"
            id="masterCanApproveReservations"
            @click="toggleAll('can_approve_reservation')"
            :indeterminate.prop="isIndeterminate('can_approve_reservation')"
            :checked="areAllChecked('can_approve_reservation')"
            v-if="user.disable_type_user"
          />
          <label for="canApproveReservations"
            >Entity, kde může schvalovat Rezervace</label
          >
        </div>
        <div class="checkboxes-wrap flex-grow-1">
          <div
            v-for="(entity, index) in entities"
            :key="entity.id"
            class="form-check checkbox-lg"
          >
            <input
              type="checkbox"
              class="form-check-input"
              :id="'canApproveReservations-' + entity.id"
              v-bind:disabled="!user.disable_type_user"
              @click="
                handleCheckboxClick('can_approve_reservation', index, $event)
              "
              v-model="entityPermissions[entity.id].can_approve_reservation"
            />
            <label
              class="form-check-label"
              :for="'canApproveReservations-' + entity.id"
              >{{ entity.category.name + "/" + entity.name }}</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../../services/api";

export default {
  data() {
    return {
      user: {
        last_name: "",
        first_name: "",
        email: "",
        role: "",
        is_active: false,
        disable_type_user: false,
        can_edit_entities_and_categories: false,
        can_edit_simple_reservations: false,
        can_see_available_entities: false,
      },
      entities: [], // List of all entities
      entityPermissions: {}, // Object to store the permissions per entity
      lastCheckedIndex: {
        can_only_see_reserved_blocks: null,
        can_make_reservation: null,
        can_see_info_in_calendar: null,
        can_approve_reservation: null,
      },
    };
  },
  created() {
    // Fetch user details from API
    this.fetchUserDetails();

    // Fetch all entities and permissions
    this.fetchEntities();
  },
  methods: {
    async fetchUserDetails() {
      const response = await api.get(`/users/${this.$route.params.id}`);
      this.user = response.data;
    },
    async fetchEntities() {
      const response = await api.get("/entities");
      this.entities = response.data;

      // Initialize entityPermissions with the existing data from the API
      this.entities.forEach((entity) => {
        this.entityPermissions[entity.id] = {
          can_only_see_reserved_blocks: false,
          can_make_reservation: false,
          can_see_info_in_calendar: false,
          can_approve_reservation: false,
        };
      });

      // Fetch the user's entity permissions
      const permissionsResponse = await api.get(
        `/users/${this.$route.params.id}/permissions`
      );
      const permissions = permissionsResponse.data || [];

      // Set the permissions for the user
      permissions.forEach((permission) => {
        this.entityPermissions[permission.entity_id] = {
          can_only_see_reserved_blocks: permission.can_only_see_reserved_blocks,
          can_make_reservation: permission.can_make_reservation,
          can_see_info_in_calendar: permission.can_see_info_in_calendar,
          can_approve_reservation: permission.can_approve_reservation,
        };
      });

      if (permissions.length == 0 || !this.user.disable_type_user) {
        const response2 = await api.get(
          `/roles/${this.user.roleid}/permissions`
        );
        this.selectedRolePermissions = response2.data;

        // Initialize entityPermissions for fetched permissions
        const permissions2 =
          this.selectedRolePermissions.entity_permissions || [];
        permissions2.forEach((permission) => {
          this.entityPermissions[permission.entity_id] = {
            can_only_see_reserved_blocks:
              permission.can_only_see_reserved_blocks,
            can_make_reservation: permission.can_make_reservation,
            can_see_info_in_calendar: permission.can_see_info_in_calendar,
            can_approve_reservation: permission.can_approve_reservation,
          };
        });
      }
    },
    async refreshPermissions() {
      if (!this.user.disable_type_user) {
        const response2 = await api.get(
          `/roles/${this.user.roleid}/permissions`
        );
        this.selectedRolePermissions = response2.data;

        this.user.can_edit_entities_and_categories =
          this.selectedRolePermissions.can_edit_entities_and_categories;
        this.user.can_edit_simple_reservations =
          this.selectedRolePermissions.can_edit_simple_reservations;
        this.user.can_see_available_entities =
          this.selectedRolePermissions.can_see_available_entities;

        // Initialize entityPermissions for fetched permissions
        const permissions2 =
          this.selectedRolePermissions.entity_permissions || [];
        permissions2.forEach((permission) => {
          this.entityPermissions[permission.entity_id] = {
            can_only_see_reserved_blocks:
              permission.can_only_see_reserved_blocks,
            can_make_reservation: permission.can_make_reservation,
            can_see_info_in_calendar: permission.can_see_info_in_calendar,
            can_approve_reservation: permission.can_approve_reservation,
          };
        });
      } else {
        const permissionsResponse = await api.get(
          `/users/${this.$route.params.id}/permissions`
        );
        const permissions = permissionsResponse.data || [];

        // Set the permissions for the user
        permissions.forEach((permission) => {
          this.entityPermissions[permission.entity_id] = {
            can_only_see_reserved_blocks:
              permission.can_only_see_reserved_blocks,
            can_make_reservation: permission.can_make_reservation,
            can_see_info_in_calendar: permission.can_see_info_in_calendar,
            can_approve_reservation: permission.can_approve_reservation,
          };
        });
      }
    },
    async savePermissions() {
      await api.post(`/users/${this.$route.params.id}`, this.user);
      await api.post(`/users/${this.$route.params.id}/permissions`, {
        entityPermissions: this.entityPermissions,
      });
      alert("Práva byla úspěšně aktualizována.");
    },
    // Check if all checkboxes in a group are selected
    areAllChecked(group) {
      return this.entities.every(
        (entity) => this.entityPermissions[entity.id][group]
      );
    },

    // Check if some checkboxes in a group are selected (for indeterminate state)
    isIndeterminate(group) {
      const checkedCount = this.entities.filter(
        (entity) => this.entityPermissions[entity.id][group]
      ).length;
      return checkedCount > 0 && checkedCount < this.entities.length;
    },

    // Toggle all checkboxes in a group
    toggleAll(group) {
      const newState = !this.areAllChecked(group);
      this.entities.forEach((entity) => {
        this.entityPermissions[entity.id][group] = newState;
      });
    },

    // Handle Shift-click to select a range of checkboxes in a group
    handleCheckboxClick(group, index, event) {
      if (event.shiftKey && this.lastCheckedIndex[group] !== null) {
        const start = Math.min(index, this.lastCheckedIndex[group]);
        const end = Math.max(index, this.lastCheckedIndex[group]);
        for (let i = start; i <= end; i++) {
          this.entityPermissions[this.entities[i].id][group] =
            event.target.checked;
        }
      }
      this.lastCheckedIndex[group] = index;
    },
  },
};
</script>

<style scoped>
.form-check {
  margin-bottom: 10px;
}
</style>
