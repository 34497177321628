<template>
  <div class="row justify-content-between mt-5 mb-2">
    <h2 class="col-12">Volné entity od {{ startDate }} do {{ endDate }}</h2>
  </div>
  <div>
    <table class="table align-middle">
      <thead class="table-light align-top">
        <tr>
          <th class="col-md-10">
            Název<br />
            <input type="text" class="mt-2" v-model="filterName"/>
          </th>
          <th class="col-md-2 text-end">
            <button class="btn btn-secondary mb-2" @click="filterData">Filtrovat</button><br />
            <button class="btn btn-secondary" @click="filterClear">Vyčistit</button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="entity in entities" :key="entity.id">
          <td>{{ entity.category.name + "/" + entity.name }}</td>
          <td class="text-end">
            <button @click="openCalendar(entity)" class="btn btn-success">
              Do kalendáře
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import api from "../services/api";

export default {
  data() {
    return {
      startDate: this.$route.query.start || "",
      endDate: this.$route.query.end || "",
      entities: [],
      filterName: '',
    };
  },
  mounted() {
    this.fetchEntities();
  },
  methods: {
    filterClear() {
      this.filterName = '';
      this.fetchEntities();
    },
    filterData() {
      this.fetchEntities();
    },
    async fetchEntities() {
      const startDate = this.$route.query.start;
      const endDate = this.$route.query.end;

      try {
        // Fetch entities filtered by start and end date
        const response = await api.get(
          `/entities?start=${startDate}&end=${endDate}&see=true`, {
          params: {
            name: this.filterName
          }
        }
        );
        this.entities = response.data;
      } catch (error) {
        console.error("Error fetching entities:", error);
      }
    },
    openCalendar(entity) {
      let start_date = this.startDate.split(" ");
      let dateOnly = start_date[1];

      // Split the date into day, month, and year
      let [day, month, year] = dateOnly.split(".");

      // Rearrange the parts to YYYY-MM-DD format
      let formattedDate = `${year}-${month}-${day}`;
      // const today = new Date().toISOString().split("T")[0]; // Example start date: today
      this.$router.push({
        path: "/calendar",
        query: {
          category_id: entity.category.id,
          entity_id: entity.id,
          start_date: formattedDate, // Or any specific date you want to preselect
        },
      });
    },
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        this.startDate = this.$route.query.start;
        this.endDate = this.$route.query.end;
        this.fetchEntities();
      },
    },
  },
};
</script>
