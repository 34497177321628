<template>
  <div
    class="tooltipcustom-container"
    @mouseenter="showTooltip"
    @mouseleave="hideTooltip"
    @focus="showTooltip"
    @blur="hideTooltip"
    ref="triggerElement"
  >
    <slot></slot> <!-- The content that triggers the tooltip -->
    <div
      v-if="visible && text"
      class="tooltipcustom"
      :class="position"
      :style="{ top: tooltipTop + 'px', left: tooltipLeft + 'px' }"
      ref="tooltip"
    >
      {{ text || 'No additional information' }} <!-- Fallback for empty text -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: null, // Allow null values
    },
    position: {
      type: String,
      default: 'top', // 'top', 'bottom', 'left', 'right'
    },
  },
  data() {
    return {
      visible: false,
      tooltipTop: -20,
      tooltipLeft: -30,
    };
  },
  methods: {
    showTooltip() {
      this.visible = true;

      // Wait for the tooltip to appear in the DOM
      this.$nextTick(() => {
        const triggerElement = this.$refs.triggerElement;
        const tooltipElement = this.$refs.tooltipcustom;

        // Ensure both elements exist
        if (!triggerElement || !tooltipElement) return;

        const triggerRect = triggerElement.getBoundingClientRect();
        const tooltipRect = tooltipElement.getBoundingClientRect();

        switch (this.position) {
          case 'top':
            this.tooltipcustomTop = triggerRect.top - tooltipRect.height + 10;
            this.tooltipcustomLeft =
              triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2;
            break;
          case 'bottom':
            this.tooltipcustomTop = triggerRect.bottom + 10;
            this.tooltipcustomLeft =
              triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2;
            break;
          case 'left':
            this.tooltipcustomTop =
              triggerRect.top + triggerRect.height / 2 - tooltipRect.height / 2;
            this.tooltipcustomLeft = triggerRect.left - tooltipRect.width - 10;
            break;
          case 'right':
            this.tooltipcustomTop =
              triggerRect.top + triggerRect.height / 2 - tooltipRect.height / 2;
            this.tooltipcustomLeft = triggerRect.right + 10;
            break;
          default:
            break;
        }
      });
    },
    hideTooltip() {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
.tooltipcustom-container {
  display: inline-block;
  position: relative;
}

.tooltipcustom {
  position: absolute;
  background-color: #eae9ee;
  color: black;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 14;
  pointer-events: none;
  visibility: visible;
  max-width: 150px;
  text-wrap: wrap;
}

.tooltipcustom.top {
  transform: translateY(-50%);
}

.tooltipcustom.bottom {
  transform: translateY(10px);
}

.tooltipcustom.left {
  transform: translateX(-10px);
}

.tooltipcustom.right {
  transform: translateX(10px);
}

.tooltipcustom::before {
  content: '';
  position: absolute;
  border-style: solid;
}

.tooltipcustom.top::before {
  border-color: transparent transparent #eae9ee transparent;
  border-width: 0 5px 5px 5px;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(180deg);
}

.tooltipcustom.bottom::before {
  border-color: #eae9ee transparent transparent transparent;
  border-width: 5px 5px 0 5px;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.tooltipcustom.left::before {
  border-color: transparent transparent transparent #eae9ee;
  border-width: 5px 0 5px 5px;
  right: -5px;
  top: 50%;
  transform: translateY(-50%);
}

.tooltipcustom.right::before {
  border-color: transparent #eae9ee transparent transparent;
  border-width: 5px 5px 5px 0;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
}

.checkboxes-wrap .tooltipcustom-container {
  display: flex;
  align-items: start;
  text-align: left;
  justify-content: start;
}
</style>