import axios from 'axios';
import { getToken, updateToken, logout } from '../keycloak';  // Ensure paths are correct

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // Your Laravel API URL
});

// Add a request interceptor to include the authorization token
api.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Add a response interceptor to handle 401 errors
api.interceptors.response.use(
  (response) => response, // Pass successful responses through
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;  // Prevent infinite retry loop

      // Try to refresh the token
      try {
        await updateToken(() => {
          // If the token refresh is successful, retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${getToken()}`;
        });
        
        // Return the retried request to pass the response back to the calling code
        return axios(originalRequest);
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        logout();  // Log the user out if the token refresh fails
        window.location.href = '/';  // Redirect to home page
      }
    }

    return Promise.reject(error);  // Reject the promise if token refresh fails or it's another error
  }
);

export default api;
