<template>
  <div v-if="isAuthenticated">
    <!-- Include the Calendar View Component for Logged-In Users -->
    <CalendarView />
  </div>

  <div class="home-container" v-else>
    <h1>Rezervační systém Městské knihovny v Praze</h1>
    <div class="home-content">
      <p>
        Vítejte v rezervačním systému Městské knihovny v Praze. Po přihlášení, nebo registraci, se<br />můžete registrovat na akce nebo vytvářet vlastní rezervace.
      </p>
      <div class="home-buttons">
        <button @click="login" class="btn btn-primary">Přihlásit</button>
        <button @click="register" class="btn btn-secondary">
          Registrovat do systému
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { isLoggedIn, login } from "../keycloak"; // Import the login function from keycloak.js
import CalendarView from "./CalendarView.vue"; // Import Calendar View

export default {
  components: {
    CalendarView, // Register Calendar View
  },
  data() {
    return {
      isAuthenticated: isLoggedIn(), // Check if user is logged in
    };
  },
  methods: {
    // Redirect to Keycloak login
    login() {
      login(); // Call the Keycloak login function
    },
    // Redirect to the Keycloak registration page
    register() {
      window.location.href = 'https://www.mlp.cz/cz/moje-knihovna/';
      //process.env.VUE_APP_KEYCLOAK_REGISTRATION_URL; // Adjust the Keycloak registration URL
    },
  },
};
</script>

<style scoped>
.home-container {
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  height: 100vh;
}

.home-content {
  text-align: left;
  background-color: #f8e5e7;
  padding: 10px;
  height: 500px;
  border-radius: 5px;
  width: 100%;
}

.home-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  max-width: 300px;
}

button {
  margin: 0;
  padding: 10px 20px;
}
</style>
