<template>
  <div class="row justify-content-between mt-5 mb-2 page-heading">
    <h2 class="col-3">Vytvořit kategorii</h2>
    <div class="col-4 d-grid gap-2 d-md-block">
      <button class="btn btn-secondary" @click="closeCategory">Zavřít</button>
      <button class="btn btn-success" @click="updateCategory">Přidat</button>
    </div>
  </div>
  <div class="container mt-4 edit-area">
    <div class="row">
      <div class="form-group col-md-4">
        <label for="lastName">Název</label>
        <input
          type="text"
          class="form-control"
          id="lastName"
          v-model="category.name"
          required
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from "../services/api";

export default {
  data() {
    return {
      category: {
        name: "",
      },
    };
  },
  methods: {
    async updateCategory() {
      try {
        await api.post(`/categories`, this.category);
        this.$router.push("/admin/categories"); // Redirect to category list after update
      } catch (error) {
        console.error("Error updating category:", error);
      }
    },
    closeCategory() {
      this.$router.push("/admin/categories");
    },
  },
};
</script>
