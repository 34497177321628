<template>
  <div class="row justify-content-between mt-5 mb-2 page-heading">
    <h2 class="col-3">Uživatelé</h2>
  </div>
  <div class="table-responsive">
    <table class="table align-middle">
      <thead class="table-light align-top">
        <tr>
          <th>Příjmení
            <br />
            <input type="text" class="mt-2" v-model="filterName"/>
          </th>
          <th>Jméno</th>
          <th>E-mail
            <br />
            <input type="text" class="mt-2" v-model="filterEmail"/>
          </th>
          <th>Typ</th>
          <th  class="col-md-2 text-end">
            <button class="btn btn-secondary mb-2" @click="filterData">Filtrovat</button><br />
            <button class="btn btn-secondary" @click="filterClear">Vyčistit</button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.last_name }}</td>
          <td>{{ user.first_name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.rolename }}</td>
          <td  class="text-end">
            <router-link :to="`/admin/users/${user.id}`" class="btn btn-success"
              >Detail</router-link
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- Pagination Controls -->
  <div class="pagination-controls">
      <button
        class="btn btn-primary"
        @click="fetchUsers(currentPage - 1)"
        :disabled="currentPage === 1"
      >
        Předchozí
      </button>
      <span>Stránka {{ currentPage }} z {{ lastPage }}</span>
      <button
        class="btn btn-primary"
        @click="fetchUsers(currentPage + 1)"
        :disabled="currentPage === lastPage"
      >
        Další
      </button>
    </div>
</template>

<script>
import api from "../../../services/api";

export default {
  data() {
    return {
      filterName: '',
      filterEmail: '',
      users: [],
      currentPage: 1,
      lastPage: 1, // Total number of pages
    };
  },
  mounted() {
    this.fetchUsers(this.currentPage);
  },
  methods: {
    async fetchUsers(page = 1) {
      try {
        const response = await api.get(`/users-list`, {
          params: {
            name: this.filterName,
            email: this.filterEmail,
            page: page,
          }
        });
        this.users = response.data.data; // `data` contains the paginated users
        this.currentPage = response.data.current_page;
        this.lastPage = response.data.last_page;
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },
    filterClear() {
      this.filterName = '';
      this.filterEmail = '';
      this.fetchUsers();
    },
    filterData() {
      this.fetchUsers();
    },

  },
};
</script>

<style>
.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
