<template>
  <div class="row justify-content-between mt-5 mb-2 page-heading">
    <h2 class="col-3">Kategorie</h2>
    <div class="col-4 gap-2 d-flex flex-end flex-row-reverse">
      <button @click="createCategory" class="btn btn-success">
        Nová kategorie
      </button>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table align-middle">
      <thead class="table-light align-top">
        <tr>
          <th class="col-md-10">Název<br />
            <input type="text" class="mt-2" v-model="filterName" /></th>
          <td  class=" col-md-2 text-end">
            <button class="btn btn-secondary mb-2 " @click="filterData">Filtrovat</button><br>
            <button class="btn btn-secondary" @click="filterClear">Vyčistit</button>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="category in categories" :key="category.id">
          <td>{{ category.name }}</td>
          <td  class="d-flex justify-content-end">
            <router-link
              :to="`/admin/categories/${category.id}`"
              class="btn btn-success"
              >Detail</router-link
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import api from "../../../services/api";

export default {
  data() {
    return {
      categories: [],
      filterName: '',
    };
  },
  mounted() {
    this.fetchCategories();
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await api.get("/categories", {
          params: {
            name: this.filterName,
            edit_category: true
          }
        });
        this.categories = response.data;
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    filterClear() {
      this.filterName = '';
      this.fetchCategories();
    },
    filterData() {
      this.fetchCategories();
    },

    createCategory() {
      // Redirect to the Reservation form with start and end time pre-filled
      this.$router.push({
        path: "/admin/categories/add",
      });
    },
  },
};
</script>
