import Keycloak from "keycloak-js";

// Create a Keycloak instance
const keycloak = new Keycloak({
  url: process.env.VUE_APP_KEYCLOAK_URL,  // Use the URL from the .env file
  realm: process.env.VUE_APP_KEYCLOAK_REALM,  // Use the realm from the .env file
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,  // Use the client ID from the .env file
});

// Function to initialize Keycloak
export const initKeycloak = (onAuthenticatedCallback, onUnauthenticatedCallback) => {
  keycloak
    .init({
      onLoad: "check-sso", // Do not require login automatically, just check if the user is already logged in
      checkLoginIframe: false, // Disable iframe login check
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html", // Optional: For silent SSO login
    })
    .then((authenticated) => {
      if (authenticated) {
        onAuthenticatedCallback();
      } else {
        if( onUnauthenticatedCallback ) {
          onUnauthenticatedCallback();
        }
        // onAuthenticatedCallback();
      }
    })
    .catch((error) => {
      console.error("Failed to initialize Keycloak:", error);
    });
};

// Function to force login when required (useful for protected routes)
export const login = () => {
  keycloak.login(); // Redirect to the Keycloak login page
};

// Function to get the token from Keycloak
export const getToken = () => keycloak.token;

// Function to check if the user is logged in
export const isLoggedIn = () => !!keycloak.token;

// Function to refresh the token
export const updateToken = (successCallback) =>
  keycloak
    .updateToken(30) // Refresh the token if it's going to expire in 30 seconds
    .then(successCallback)
    .catch((error) => {
      console.error("Failed to refresh token:", error);

      window.location.href = '/';
    });

// Function to log out
export const logout = () => keycloak.logout();

export default keycloak;
