<template>
  <div class="row justify-content-between mt-5 mb-2 page-heading">
    <h2 class="col-3">Entity</h2>
    <div class="col-4 gap-2 d-flex flex-end flex-row-reverse">
      <button @click="createEntity" class="btn btn-success">Nová entita</button>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table align-middle">
      <thead class="table-light align-top">
        <tr>
          <th>Název<br />
            <input type="text" class="mt-2" v-model="filterName"/></th>
          <th>Kategorie<br />
            <select v-model="filterCategory" class="form-select">
              <option value=""></option>
              <option v-for="entity in categories" :key="entity.id" :value="entity.id">
                {{ entity.name }}
              </option>
            </select>
          </th>
          <th>Aktivní</th>
          <th  class="col-md-2 text-end">
            <button class="btn btn-secondary mb-2" @click="filterData">Filtrovat</button><br />
            <button class="btn btn-secondary" @click="filterClear">Vyčistit</button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="entity in entities" :key="entity.id">
          <td>{{ entity.name }}</td>
          <td>{{ entity.category.name }}</td>
          <td>{{ entity.active ? "Ano" : "Ne" }}</td>
          <td  class="d-flex justify-content-end">
            <router-link :to="`/entities/${entity.id}/edit`" class="btn btn-success"
              >Detail</router-link
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import api from "../../../services/api";

export default {
  data() {
    return {
      entities: [],
      filterName: '',
      filterCategory: '',
      categories: [],
    };
  },
  mounted() {
    this.fetchEntities();
    this.fetchCategories();
  },
  methods: {
    filterClear() {
      this.filterName = '';
      this.filterCategory = '';
      this.fetchEntities();
    },
    filterData() {
      this.fetchEntities();
    },
    async fetchEntities() {
      try {
        const response = await api.get("/entities-list", {
          params: {
            name: this.filterName,
            category_id: this.filterCategory,
          }
        });
        this.entities = response.data;
      } catch (error) {
        console.error("Error fetching entities:", error);
      }
    },
    // Fetch all categories
    async fetchCategories() {
      try {
        const response = await api.get("/categories");
        this.categories = response.data;
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    createEntity() {
      // Redirect to the Reservation form with start and end time pre-filled
      this.$router.push({
        path: "/entities/create",
      });
    },
  },
};
</script>
 