import { createRouter, createWebHistory } from "vue-router";
import CategoryDetail from "@/components/CategoryDetail.vue";
import EntityList from "@/components/EntityList.vue";
// import ReservationDetail from "@/components/ReservationDetail.vue";
import CalendarView from "@/components/CalendarView.vue";
import SimpleReservationForm from "@/components/SimpleReservationForm.vue";
import ReservationForm from "@/components/ReservationForm.vue";
import HomePage from "@/components/HomePage.vue";
import AdminReservations from "@/components/Pages/ReservationsList.vue";
import MyRegistrations from "@/components/Pages/MyRegistrations.vue";
import MyReservations from "@/components/Pages/MyReservations.vue";
import AdminUsers from "@/components/Pages/Admin/AdminUsers.vue";
import AdminCategories from "@/components/Pages/Admin/AdminCategories.vue";
import AdminEntities from "@/components/Pages/Admin/AdminEntities.vue";
import RolePermissions from "@/components/RolePermissions.vue";
import AdminUserDetail from "@/components/Pages/Admin/AdminUserDetail.vue";
import CategoryDetailCreate from "@/components/CategoryDetailCreate.vue";
import EntityForm from "../components/EntityForm.vue";
import GuestReservationRegistration from "../components/GuestReservationRegistration.vue"; // Guest registration component

import keycloak, { isLoggedIn } from "@/keycloak"; // Import the isLoggedIn and login functions
// import ReservationRegistration from "@/components/ReservationRegistration.vue";

const routes = [
  { path: "/", component: HomePage }, // Public homepage
  {
    path: "/entities",
    component: EntityList,
    meta: {
      requiresAuth: true,
      title: "Entity",
    },
  },
  {
    path: "/reservations/:id",
    component: ReservationForm,
    meta: { requiresAuth: true },
  },

  { path: "/calendar", component: CalendarView, meta: { requiresAuth: true } },
  {
    path: "/my-registrations",
    component: MyRegistrations,
    meta: { requiresAuth: true },
  },
  {
    path: "/simple-reservation",
    component: SimpleReservationForm,
    meta: { requiresAuth: true },
  },
  {
    path: "/simple-reservation/:id",
    component: SimpleReservationForm,
    meta: { requiresAuth: true },
  },
  {
    path: "/reservation",
    component: ReservationForm,
    meta: { requiresAuth: true, title: "Editace Rezervace" }, //adminOnly: true
  },
  {
    path: "/my-reservations",
    component: MyReservations,
    meta: { requiresAuth: true, title: "Moje Rezervace" },
  },

  {
    path: "/reservations",
    component: AdminReservations,
    meta: { requiresAuth: true, adminOnly: true,title: "Rezervace" },
  },
  {
    path: "/admin/users",
    component: AdminUsers,
    meta: { requiresAuth: true, adminOnly: true,title: "Uživatelé" },
  },
  {
    path: "/admin/users/:id",
    component: AdminUserDetail,
    meta: { requiresAuth: true, adminOnly: true,title: "Editace uživatele" },
  },
  {
    path: "/admin/categories",
    component: AdminCategories,
    meta: { requiresAuth: true, canEditEntitiesAndCategories: true,title: "Kategorie"},
  },
  {
    path: "/admin/entities",
    component: AdminEntities,
    meta: { requiresAuth: true, canEditEntitiesAndCategories: true,title: "Entity" },
  },
  {
    path: "/admin/roles",
    component: RolePermissions,
    meta: { requiresAuth: true, adminOnly: true,title: "Nastavení práv" },
  },
  {
    path: "/admin/categories/add",
    component: CategoryDetailCreate,
    meta: { requiresAuth: true, canEditEntitiesAndCategories: true,title: "Editace kategorie" },
  },
  {
    path: "/admin/categories/:id",
    component: CategoryDetail,
    meta: { requiresAuth: true, canEditEntitiesAndCategories: true,title: "Editace kategorie" },
  },
  {
    path: "/entities/create",
    component: EntityForm,
    meta: { requiresAuth: true, canEditEntitiesAndCategories: true,title: "Editace entity" },
  },
  {
    path: "/entities/:id/edit",
    component: EntityForm,
    meta: { requiresAuth: true, canEditEntitiesAndCategories: true,title: "Editace entity" },
  },
  {
    path: "/reservations/:id/register",
    component: GuestReservationRegistration, // Registration component
    beforeEnter: async (to, from, next) => {
      const reservationId = to.params.id;

      try {
        // Fetch the reservation details from the API to check login_only_access
        const response = await fetch(
          process.env.VUE_APP_API_BASE_URL +
            `/reservations-details/${reservationId}`
        );
        const reservation = await response.json();
        if( reservation.entity.active && reservation.approved ) {
          if (reservation.login_only_access && !keycloak.authenticated) {
            // If login_only_access is true and user is not authenticated, redirect to login
            keycloak.login({ redirectUri: window.location.href });
          } else {
            next(); // Proceed to the registration form
          }
        } else {
          next("/");
        }
      } catch {
        next("/");
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation guard to check for authentication
router.beforeEach(async (to, from, next) => {
  const isAuthenticated = isLoggedIn(); // Replace with your actual authentication check

  // Set document title from meta title
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "Rezervační systém"; // fallback title
  }

  if (to.meta.requiresAuth && !isAuthenticated) {
    next("/"); // Trigger Keycloak login if not authenticated
  } else if (to.meta.adminOnly) {
    const userRole = localStorage.getItem("userRole");
    if (userRole !== "admin") {
      next("/"); // Redirect to homepage if user is not an admin
    } else {
      next(); // Allow access if user is an admin
    }
  } else if (to.meta.canEditEntitiesAndCategories) {
    let user = localStorage.getItem("user");
    let canEditEntityCategory = false;
    if (user) {
      user = JSON.parse(user);
      canEditEntityCategory = user.can_edit_entities_and_categories;
    }

    if (!canEditEntityCategory) {
      next("/"); // Redirect to homepage if user is not an admin
    } else {
      next(); // Allow access if user is an admin
    }
  } else {
    next(); // Proceed to the route
  }
});

export default router;
